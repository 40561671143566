import React from 'react';
import './Services.css'; 

const Services = () => {
  return (
    <div className="services-container">
      <h1 className="coming-soon-text">Coming Soon!</h1>
    </div>
  );
}

export default Services;
