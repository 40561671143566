import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { AboutUs, Footer, Gallery, Header, Offerings, Word } from "./container";
import NavbarSecondary from "./container/Navbar2/NavbarSecondary";
import Navbar from "./container/Navbar/Navbar";
import Products from "./container/Products/Products";
import Services from "./container/Services/Services";
import NotFound from "./container/NotFound/NotFound"; 
import "./App.css";

const App = () => {
  const location = useLocation();

  const NavbarComponent =
    location.pathname === "/products" || location.pathname === "/services" && location.pathname !== '/'
      ? NavbarSecondary
      : Navbar;

  return (
    <>
{ (location.pathname === '/' || location.pathname === '/services' || location.pathname === '/products') && <NavbarComponent /> }

      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <AboutUs />
              <Gallery />
              <Offerings />
              <Word />
            </>
          }
        />
        <Route path="/products" element={<Products />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      { (location.pathname === '/' || location.pathname === '/services' || location.pathname === '/products') && <Footer /> }
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
