import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoCloseSharp } from 'react-icons/io5';
import images from '../../constants/images';
import { useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';


const NavbarSecondary = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleServicesClick = (path) => {
    navigate(path);
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.memoraLogo} alt="app__logo" loading="lazy" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans"><a onClick={handleHomeClick}>Home</a></li>
        <li className="p__opensans"><a onClick={() => handleServicesClick(location.pathname === "/services" ? '/products' : '/services')}>{location.pathname === "/services" ? 'Products' : 'Services'}</a></li>
      </ul>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#575756" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <IoCloseSharp fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} style={{marginTop: '50px', marginRight: '15px'}} />
            <ul className="app__navbar-smallscreen_links">
              <li><a onClick={handleHomeClick}>Home</a></li>
              <li><a onClick={handleServicesClick}>Services</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavbarSecondary;
