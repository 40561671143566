import React from "react";
import { images } from '../../constants';

const NotFound = () => (
  <div
    style={{
      backgroundColor: '#A794C6',
      textAlign: 'center',
      padding: '3rem',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <img
      src={images.memoraWhiteLogo}
      alt="Logo"
      style={{ width: '200px', marginBottom: '1rem' }}
    />
    <p style={{ fontSize: '1.2rem', color: '#fff' }}>
      The page you requested is unavailable.{' '}
      <a href="/" style={{ color: '#fff', textDecoration: 'underline' }}>Go back</a>
    </p>
  </div>
);

export default NotFound;
