import React from 'react';
import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    
    <div className="app__aboutus-content flex__center">
      
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant" style={{ color: '#fff', fontSize: '36px', marginBottom: '20px' }}>Our Story</h1>
        <img 
          src={images.MInitial} 
          alt="about_spoon" 
          className="spoon__img" 
          loading="lazy" 
          style={{ width: '60px', margin: '20px 0' }} 
        />
        <p className="p__opensans" style={{ color: '#fff', fontSize: '18px', lineHeight: '1.6', textAlign: 'justify', textTransform: 'none', margin: 0, padding: 0, letterSpacing: 'normal' }}>
          Memora was born from a passion and a desire to bring something fresh and exciting to the world of events and gifting.
          We aim to create a space where people can discover unique and creative gifts for any occasion. Our journey began with a simple idea: to offer thoughtful and memorable gifts that stand out from the traditional options. <br/> As we continue this adventure, our goal is to infuse joy and magic into every celebration.
        </p>
      </div>

      <div className="app__aboutus-content_knife flex__center" />
      
      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant" style={{ color: '#fff', fontSize: '36px', marginBottom: '20px', textTransform: 'none'  }}>Our Values</h1>
        <img 
          src={images.MInitial} 
          alt="about_spoon" 
          className="spoon__img" 
          loading="lazy" 
          style={{ width: '60px', margin: '20px 0' }} 
        />

        {/* Innovation Section */}
        <div className="value-section" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
          <img 
            src={images.amblem} 
            alt="innovation_icon" 
            style={{ width: '40px', marginBottom: '10px' }} 
          />
          <p className="p__opensans" style={{ color: '#fff', fontSize: '17px', lineHeight: '1.8', textAlign: 'center', textTransform: 'none', margin: 0, padding: 0, letterSpacing: 'normal', flex: 1 }}>
            <strong style={{ fontSize: '24px' }}>Innovation</strong> <br/> We embrace creativity to deliver unique gifts that make every celebration feel special and memorable.
          </p>
        </div>

        {/* Joy Section */}
        <div className="value-section" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
          <img 
            src={images.amblem} 
            alt="joy_icon" 
            style={{ width: '40px', marginBottom: '10px' }} 
          />
          <p className="p__opensans" style={{ color: '#fff', fontSize: '17px', lineHeight: '1.8', textAlign: 'center', textTransform: 'none', margin: 0, padding: 0, letterSpacing: 'normal', flex: 1 }}>
            <strong style={{ fontSize: '24px' }}>Joy</strong> <br/> We spread happiness through thoughtful gifts, creating moments that bring smiles and strengthen connections.
          </p>
        </div>

        {/* Community Section */}
        <div className="value-section" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
          <img 
            src={images.amblem} 
            alt="community_icon" 
            style={{ width: '40px', marginBottom: '10px' }} 
          />
          <p className="p__opensans" style={{ color: '#fff', fontSize: '17px', lineHeight: '1.8', textAlign: 'center', textTransform: 'none', margin: 0, padding: 0, letterSpacing: 'normal', flex: 1 }}>
            <strong style={{ fontSize: '24px' }}>Community</strong> <br/> We believe in the power of connection, supporting local artisans, and fostering a vibrant community where creativity and joy flourish.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default AboutUs;
