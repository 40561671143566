import React from "react";
import { Box, Typography } from "@mui/material";

const Word = () => (
  <Box
    sx={{
      backgroundColor: "#f5f5f5",
      minHeight: "60vh", 
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
    }}
  >
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        padding: "30px",
        borderRadius: "12px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 6px 10px",
        textAlign: "center",
        maxWidth: "700px",
        margin: "40px auto",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Decorative Circles */}
      <Box
        sx={{
          position: "absolute",
          width: "100px",
          height: "100px",
          backgroundColor: "#A794C6",
          borderRadius: "50%",
          top: "-50px",
          left: "-50px",
          opacity: 0.5,
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: "150px",
          height: "150px",
          backgroundColor: "#A794C6",
          borderRadius: "50%",
          bottom: "-75px",
          right: "-75px",
          opacity: 0.3,
          zIndex: 0,
        }}
      />
      <Typography
        variant="h4"
        sx={{
          color: "#A794C6",
          fontWeight: "bold",
          marginBottom: "20px",
          fontFamily: "Arial, sans-serif",
          position: "relative",
          zIndex: 1,
          textTransform: "uppercase",
          letterSpacing: "2px",
        }}
      >
        A Word from Memora
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "#575756",
          fontSize: "17px",
          lineHeight: "1.8",
          fontFamily: "Arial, sans-serif",
          position: "relative",
          zIndex: 1,
        }}
      >
        Welcome to the World of <strong>Memora</strong>, where creativity meets
        celebration!
        <br />
        <strong>Memora</strong> is not just a brand; it’s a dream fueled by my
        passion for drawing smiles and creating lasting memories. Each concept
        reflects my belief that every occasion deserves a touch of magic, and I’m
        excited to share that with you!
        <Typography
          sx={{
            fontFamily: "'Dancing Script', cursive",
            marginTop: '10px',
            color: "#A794C6",
            fontSize: "22px",
            position: "relative",
            zIndex: 1,
            fontWeight: 600
          }}
        >
          With love
        </Typography>
      </Typography>
      <Box
        sx={{
          width: "80px",
          height: "4px",
          backgroundColor: "#A794C6",
          margin: "20px auto",
          borderRadius: "2px",
          position: "relative",
          zIndex: 1,
        }}
      />
    </Box>
  </Box>
);

export default Word;
