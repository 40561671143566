import React from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from '../../constants';
import './Header.css';

const Header = () => {


  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate('/products');
  };

  return (
    <div className="app__header app__wrapper section__padding" id="home">
      <div className="app__wrapper_info">
        <h1 className="app__header-h1">Personalized Creations for Every Moment</h1>
        <p className="p__opensans" style={{ margin: '2rem 0', color: '#575756', fontSize: '16px' }}>
          At Memora, we turn your special moments into lasting memories with personalized, unique creations.
        </p>
        <button type="button" className="custom__button" onClick={handleViewMoreClick} style={{alignItems: 'center', marginTop: '-20px'}}>Discover Our Collection</button>
      </div>

      <div className="app__wrapper_img">
        <img src={images.landing} alt="header_img" loading="lazy" />
      </div>
    </div>
  );
};

export default Header;
