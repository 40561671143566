import React from 'react';
import { FiInstagram } from 'react-icons/fi';
import { FaWhatsapp } from "react-icons/fa6";

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
      </div>

      <div className="app__footer-links_logo">
        <img src={images.memoraWhiteLogo} alt="footer_logo" loading="lazy" />
        <p className="p__opensans" style={{color: 'white'}}>~ The best thing about memories is making them!</p>
        <div className="app__footer-links_icons">
          {/* <FiFacebook />
          <FiTwitter /> */}
          <a href="https://www.instagram.com/memoralb?igsh=b3k3ZjB0N3ZxbDJm" target="_blank" rel="noopener noreferrer">
            <FiInstagram />
          </a>
          <a href="https://wa.me/+96181367567?text=Hello%20there!"  target="_blank" rel="noopener noreferrer">
          <FaWhatsapp />
          </a>
        </div>
      </div>

      <div className="app__footer-links_work">
        {/* <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Monday-Friday:</p>
        <p className="p__opensans">08:00 am - 12:00 am</p>
        <p className="p__opensans">Saturday-Sunday:</p>
        <p className="p__opensans">07:00 am - 11:00 pm</p> */}
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans" style={{marginTop: '-30px'}}>2024 Memora. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;
