import memoraLogo from "../assets/MemoraBlackTransparentBg.png";
import amblem from "../assets/whiteAmblem.jpeg";
import MInitial from "../assets/whiteM.jpeg";
import memoraWhiteLogo from "../assets/whiteLogo.jpeg";
import greyAmblem from "../assets/greyAmblem.jpeg";
import comingSoon from "../assets/ComingSoon.png";
import welcomePage from "../assets/blanket-welcomePage.jpeg";
import cupWelcomePage from "../assets/cup-welcomePage.jpeg";
import landing from "../assets/landing.jpg";
import featured3 from "../assets/featured/featured3.jpeg";
import featured4 from "../assets/featured/featured4.jpeg";
import featured5 from "../assets/featured/featured5.jpeg";
import featured6 from "../assets/featured/featured6.jpeg";
import featuredBoule from "../assets/featured/bouleNoel.jpeg";

import product1 from "../assets/products/product1.jpeg";
import product2 from "../assets/products/product2.jpeg";
import product3 from "../assets/products/product3.jpeg";
import product31 from "../assets/products/product3.1.jpeg";
import product4 from "../assets/products/product4.jpeg";
import product41 from "../assets/products/product4.1.jpeg";
import product42 from "../assets/products/product4.2.jpeg";
import product5 from "../assets/products/product5.jpeg";
import product51 from "../assets/products/product5.1.jpeg";
import product6 from "../assets/products/product6.jpeg";
import product61 from "../assets/products/product6.1.jpeg";
import product7 from "../assets/products/product7.jpeg";
import product8 from "../assets/products/product8.jpeg";
import product9 from "../assets/products/product9.jpeg";
import product10 from "../assets/products/product10.jpeg";
import product11 from "../assets/products/product11.jpeg";
import product12 from "../assets/products/product12.jpeg";
import product13 from "../assets/products/product13.jpeg";
import product131 from "../assets/products/product13.1.jpeg";
import product14 from "../assets/products/product14.jpeg";
import product15 from "../assets/products/product15.jpeg";
import product16 from "../assets/products/product16.jpeg";
import product161 from "../assets/products/product16.1.jpeg";
import product17 from "../assets/products/product17.jpeg";
import product18 from "../assets/products/product18.jpeg";
import product19 from "../assets/products/product19.jpeg";
import product20 from "../assets/products/product20.jpeg";
import product201 from "../assets/products/product20.1.jpeg";
import product21 from "../assets/products/product21.jpeg";
import product22 from "../assets/products/product22.jpeg";

import product71 from "../assets/products/product7.1.jpeg";
import product81 from "../assets/products/product8.1.jpeg";
import product101 from "../assets/products/product10.1.jpeg";
import product111 from "../assets/products/product11.1.jpeg";
import product121 from "../assets/products/product12.1.JPG";
import product141 from "../assets/products/product14.1.jpeg";
import product171 from "../assets/products/product17.1.jpeg";
import product151 from "../assets/products/product15.1.jpeg";
import product191 from "../assets/products/product19.1.jpeg";
import grandexperience from "../assets/products/Grand-experience-.jpeg";

import id231 from "../assets/products/id 23-1.jpeg";
import id232 from "../assets/products/id 23-2.jpeg";
import id24 from "../assets/products/id 24.jpeg";
import id251 from "../assets/products/id 25-1.jpeg";
import id252 from "../assets/products/id 25-2.jpeg";
import id253 from "../assets/products/id 25-3.jpeg";
import id261 from "../assets/products/id 26-1.jpeg";
import id262 from "../assets/products/id 26-2.jpeg";
import id271 from "../assets/products/id 27-1.jpeg";
import id272 from "../assets/products/id 27-2.jpeg";
import id281 from "../assets/products/id 28-1.jpeg";
import id282 from "../assets/products/id 28-2.jpeg";
import id291 from "../assets/products/id 29-1.jpeg";
import id292 from "../assets/products/id 29-2.jpeg";
import id301 from "../assets/products/id 30-1.jpeg";
import id302 from "../assets/products/id 30-2.jpeg";
import id31 from "../assets/products/id 31.jpeg";
import id321 from "../assets/products/id 32-1.jpeg";
import id322 from "../assets/products/id 32-2.jpeg";
import id331 from "../assets/products/id 33-1.jpeg";
import id332 from "../assets/products/id 33-2.jpeg";
import id341 from "../assets/products/id 34-1.jpeg";
import id342 from "../assets/products/id 34-2.jpeg";
import id351 from "../assets/products/id 35-1.jpeg";
import id352 from "../assets/products/id 35-2.jpeg";

export default {
  amblem,
  memoraLogo,
  memoraWhiteLogo,
  MInitial,
  greyAmblem,
  comingSoon,
  welcomePage,
  cupWelcomePage,
  landing,
  featured3,
  featured4,
  featured5,
  featured6,
  featuredBoule,
  product1,
  product2,
  product3,
  product31,
  product4,
  product41,
  product42,
  product5,
  product51,
  product6,
  product61,
  product7,
  product71,
  product8,
  product81,
  product9,
  product10,
  product101,
  product11,
  product111,
  product12,
  product121,
  product13,
  product131,
  product14,
  product141,
  product15,
  product151,
  product16,
  product161,
  product17,
  product171,
  product18,
  product19,
  product191,
  product20,
  product201,
  product21,
  product22,
  grandexperience,
  id231,
  id232,
  id24,
  id251,
  id252,
  id253,
  id261,
  id262,
  id271,
  id272,
  id281,
  id282,
  id291,
  id292,
  id301,
  id302,
  id31,
  id321,
  id322,
  id331,
  id332,
  id341,
  id342,
  id351,
  id352,
};
