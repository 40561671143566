import React from 'react';

import { data } from '../../constants';
import './Offerings.css';

const AwardCard = ({ award: { imgUrl, title, subtitle } }) => (
  <div className="app__laurels_awards-card">
    <img src={imgUrl} alt="awards" loading="lazy" />
    <div className="app__laurels_awards-card_content">
      <p className="p__cormorant" style={{ color: 'white' }}>{title}</p>
      <p className="p__opensans" style={{ color: 'white', fontSize: '18px' }}>{subtitle}</p>
    </div>
  </div>
);

const Offerings = () => (
  <div className="app__bg app__wrapper section__padding" id="awards">
    <div className="app__wrapper_info">
      <h1 className="headtext__cormorant" style={{color: 'white', alignItems: 'center'}}>What we offer</h1>

      <div className="app__laurels_awards">
        {data.services.map((award) => <AwardCard award={award} key={award.title} />)}
      </div>
    </div>
  </div>
);

export default Offerings;
